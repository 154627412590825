$(function() {
    //
    // Smooth Sroll
    //
    $('a[href*="#"]:not([href$="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'')
            && location.hostname === this.hostname
            && $(this).data('toggle') === undefined
            && $(this).data('slide') === undefined) {
            let $target = $(this.hash);
            $target = $target.length && $target || $('[name=' + this.hash.slice(1) +']');
            if ($target.length) {
                let targetOffset = $target.offset().top + 10;
                let navbar = $('.navbar-wrapper');
                if(JSH.viewport().width > 991) {
                    if (navbar.length && targetOffset !== 0) {
                        targetOffset -= navbar.outerHeight();
                    }
                }
                $('html,body').animate({
                    scrollTop: targetOffset
                }, 500);
                return false;
            }
        }
    });

    if (window.location.hash) {
        var $target = $(window.location.hash);
        if ($target.length) {
            let targetOffset = $target.offset().top + 10;
            let navbar = $('.navbar-wrapper');
            if(navbar.length && targetOffset !== 0){
                targetOffset -= navbar.outerHeight();
            }
            $('html,body').animate({scrollTop: targetOffset}, 500, 'linear', function () {
                // Animation complete. Adjust Offset if Height changed during Scrolling.
                var offsetfixnavigation = 0;

                if(JSH.viewport().width > 991) {
                    offsetfixnavigation = 140;

                    if(navbar.length && targetOffset !== 0){
                        targetOffset -= navbar.outerHeight();
                    }
                }

                let newOffset = $target.offset().top - offsetfixnavigation;
                if (targetOffset !== newOffset) {
                    $('html,body').animate({scrollTop: newOffset}, 300, 'linear');
                }
            });
            return false;
        }
    }

    //
    // Scroll to top
    //
    $('.scroll-top').on('click', function() {
        $(this).blur();
    });
    $(window).on('scroll', function () {
        if ($(this).scrollTop() > 300) {
            $('.scroll-top').addClass('scroll-top-visible');
        } else {
            $('.scroll-top').removeClass('scroll-top-visible');
        }
    });

    $(window).on('scroll', function () {
        if ($(this).scrollTop() > 50) {
            $('.navbar').addClass('scrolled');
        } else {
            $('.navbar').removeClass('scrolled');
        }
    });

});
