$(function() {

    $('.navbar-toggler').on('click', function () {
        if($('.navbar').hasClass('no-scroll')) {
            $('.navbar').removeClass('no-scroll');
        } else {
            $('.navbar').addClass('no-scroll');
        }

        if($('html').hasClass('menu-open')) {
            $('html').removeClass('menu-open');
        } else {
            $('html').addClass('menu-open');
        }
    });

    /**
     * Solution to enable links on dropdowns, the link will only be triggered
     * if the dropdown is visible. On touch devices you will need to double
     * click on a dropdown, the first click will open the menu.
     */

    if(JSH.viewport().width > 991) {
        function navbarPointerOver($element) {
            if($('.navbar-toggler').is(':hidden') && !$element.hasClass('open')) {
                $element.parent().parent().find('li').removeClass('show');
                $element.addClass('show');
                $element.find('> .dropdown-toggle').attr("aria-expanded", "true");
                $element.find('> .dropdown-menu').addClass('show');
            }
        }
        function navbarPointerLeave($element) {
            if ($('.navbar-toggler').is(':hidden')) {
                $element.removeClass('show');
                $element.find('> .dropdown-toggle').attr("aria-expanded", "false");
                $element.find('> .dropdown-menu').removeClass('show');
            }
        }

        $(document).on('pointerover', 'li.dropdown-hover', function (e) {
            if (e.originalEvent.pointerType === "mouse") {
                navbarPointerOver($(this));
            }
        });
        $(document).on('mouseenter', 'li.dropdown-hover', function () {
            if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
                navbarPointerOver($(this));
            }
        });
        $(document).on('pointerleave', 'li.dropdown-hover', function (e) {
            if (e.originalEvent.pointerType === "mouse") {
                navbarPointerLeave($(this));
            }
        });
        $(document).on('mouseleave', 'li.dropdown-hover', function () {
            if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
                navbarPointerLeave($(this));
            }
        });

        $(document).on('click', '.dropdown-toggle', function(e) {
            if ($(this).parent().hasClass('dropdown') && !$(this).parent().hasClass('show')) {
                $(this).parent().parent().parent().find('.dropdown').removeClass('show');
                $('.navbar').removeClass('no-scroll');
                $(this).parent().parent().parent().find('.dropdown').find('> .dropdown-toggle').attr('aria-expanded', 'false');
                $(this).parent().parent().parent().find('.dropdown').find('> .dropdown-menu').removeClass('show');
                $(this).parent().addClass('show');
                $('.navbar').addClass('no-scroll');
                $(this).parent().find('> .dropdown-toggle').attr('aria-expanded', 'true');
                $(this).parent().find('> .dropdown-menu').addClass('show');
                e.stopImmediatePropagation();
                e.preventDefault();
                return false;
            }
        });
        $('#main').on('click', function () {
            if($('#navbarMain').find('.dropdown.show a.dropdown-toggle').length) {
                var $link = $('#navbarMain').find('.dropdown.show a.dropdown-toggle');
                $link.parent().removeClass('show');
                $('.navbar').removeClass('no-scroll');
                $link.parent().find('> .dropdown-toggle').attr('aria-expanded', 'false');
                $link.parent().find('> .dropdown-menu').removeClass('show');


            }
        });
    }
});
