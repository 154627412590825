$(function() {
    $('html').removeClass('no-js');

    $(document).on('mouseenter', 'div.scroll-top', function () {
        $('.scroll-top').removeClass('mouseleave-animation');
        $('.scroll-top').addClass('mouseenter-animation');
    });

    $(document).on('mouseleave', 'div.scroll-top', function () {
        $('.scroll-top').removeClass('mouseenter-animation');
        $('.scroll-top').addClass('mouseleave-animation');
    });

    // Google Analytics Event Tracking
    $("a[href^='tel:']").on('click', function (e){
        var eCategory = 'Kontakt',
            eAction = 'Telefon Link Klick',
            eLabel = $(this).attr('href').replace('tel:', '');

        if (typeof gtag === 'function') {
            gtag('event', eAction, {
                'event_category': eCategory,
                'event_label': eLabel
            });
        }
    });

    $("a[href^='mailto:']").on('click', function (e){
        var eCategory = 'Kontakt',
            eAction = 'E-Mail Link Klick',
            eLabel = $(this).attr('href').replace('mailto:', '');

        if (typeof gtag === 'function') {
            gtag('event', eAction, {
                'event_category': eCategory,
                'event_label': eLabel
            });
        }
    });
});